import { createI18nApi } from "i18nifty";
import { statefulObservableToStatefulEvt } from "powerhooks/tools/StatefulObservable/statefulObservableToStatefulEvt";
import { fallbackLanguage, languages } from "./Language";

const {
    useTranslation,
    resolveLocalizedString,
    useLang,
    $lang,
    useResolveLocalizedString,
} = createI18nApi<
    | typeof import("App/AppHeader").i18n
    | typeof import("pages/FourOhFour").i18n
    | typeof import("pages/Documentation/Documentation").i18n
    | typeof import("pages/Documentation/DocumentationCard").i18n
    | typeof import("pages/Home/Home").i18n
    | typeof import("pages/Home/BetaCapabilitiesTable").i18n
>()(
    {
        languages,
        fallbackLanguage,
    },
    {
        "en": {
            "AppHeader": {
                "platform": "Platform",
                "viewer": "Integrate data",
                "exploreViewer": "Explore data",
                "home": "Home",
                "datalab": "Datalab",
                "tutorials": "Trainings and tutorials",
                "about": "About EDITO",
                "documentation": "Documentation",
                "support": "Support",
            },
            "FourOhFour": {
                "not found": "Page not found",
            },
            "Documentation": {
                "search": "Search",
                "pageTitle": "Trainings and tutorials",
                "pageHelpTitle":
                    "Discover and learn ocean data science at your own pace, according to your needs",
                "pageHelpContentP1": "Follow trainings or interactive tutorials and",
                "pageHelpContentP2": "contribute to the community resources.",
                "trainings": "Trainings",
                "no documentation found": "No documentation or training found",
                "no result found": ({ forWhat }) => `No results found for ${forWhat}`,
                "check spelling": `Check spelling or widen the search`,
                "go back": "Go back",
                "show all": "Show all",
                "discover EDITO": "Discover EDITO",
                "Ocean modelling": "Ocean modelling",
                "Ocean data quality": "Ocean data quality",
                "Data visualization": "Data visualization",
                "What-If applications": "What-If applications",
                "Focus applications": "Focus applications",
                "training courses with R": "R training courses",
                "training courses with python": "Python training courses",
                "training courses in data science": "Data science training courses",
                "best practices": "Best practices",
                "contributors": "contributors",
            },
            "DocumentationCard": {
                "open": "Open",
                "read": "Read",
                "run": "Run",
                "and": "and",
                "others": "others",
                "discover": "Discover",
                "learn": "Learn",
                "consolidate": "Consolidate",
                "deepen": "Deepen",
                "explore": "Explore",
                "create": "Create",
                "contribute": "Contribute",
            },
            "Home": {
                "title": "European Digital Twin Ocean Core Infrastructure",
                "subtitle":
                    "The European platform for discovering, visualizing, and leveraging products and knowledge to power digital twins of the ocean",
                "tutorialCardTitle": "Learn about the ocean data science",
                "tutorialCardText":
                    "Discover how to contribute to reproducible science with trainings and tutorials",
                "tutorialCardButtonLabel": "Browse our tutorials",
                "datalabCardTitle":
                    "Design and run digital twin ocean applications with your favorite tools",
                "datalabCardText":
                    "Take advantage of near-data computing, collaborate, and build end-user apps and reproducible processes",
                "datalabCardButtonLabel": "Discover the Datalab",
                "viewerCardTitle": "Explore oceanographic data",
                "viewerCardText": "View public data and create private data catalogs",
                "viewerCardButtonLabel": "Integrate data",
                "viewerCardLeftButtonLabel": "Explore data",
                "editoApplicationsTitle": "Discover EDITO applications",
                "diveIntoEditoTitle": "Dive into EDITO",
            },
            "BetaCapabilitiesTable": {
                "features": "FEATURES",
                "explore1": "EXPLORE",
                "explore2": "Use the European Digital Twin Ocean",
                "explore3": "Open without account",
                "create1": "CREATE",
                "create2": "Build your external third-party components",
                "create3": "Open to beta-testers",
                "contribute1": "CONTRIBUTE",
                "contribute2": "Add data, processes, services and tutorials",
                "contribute3": "Open to privileged beta-testers",
                "feature1":
                    "Explore the catalog of data with the viewer and discover the public assets",
                "feature2":
                    "Explore the catalog of processes (data extraction, re-analysis, forecast, What-If scenarios, etc.)",
                "feature3":
                    "Explore the catalog of services (tools, applications, etc.) and access running public services",
                "feature4": "Access tutorials & documentation",
                "feature5": "Add and publish data & create your own data catalog",
                "feature6":
                    "Process data on-demand & launch pre-defined computation on the browsing data",
                "feature7":
                    "Launch pre-defined ocean services (tools, What-If application, automation, R&D IDE, etc.) & benefit from cloud near-data computing",
                "feature8":
                    "Connect external projects and platforms such as EuroHPC, GitHub, GitLab, Kaggle, etc.",
                "feature9": "Publish quality control flag over data and processes",
                "feature10": "Add & publish new services to the catalog",
                "feature11": "Add & publish new processes to the catalog",
                "feature12": "Add & publish new tutorials to promote your contribution",
                "feature13":
                    "Work in groups with your teammates, partners or collaborators",
                "feature14": "Request more resources capabilities (Computing & Storage)",
                "feature15": "Benefit from GPUs",
                "feature16":
                    "Benefit from distributing cloud-computing clusters (Dask, Spark, etc.)",
                "feature17": "Benefit from a privileged user support",
            },
        },
        /* spell-checker: disable */
        "fr": {
            "AppHeader": {
                "platform": "Plateforme",
                "viewer": "Intégrer les données",
                "exploreViewer": "Explorer les données",
                "home": "Accueil",
                "datalab": "Datalab",
                "tutorials": "Formations et tutoriels",
                "about": "A propos d'EDITO",
                "documentation": "Documentation",
                "support": "Support",
            },
            "FourOhFour": {
                "not found": "page non trouvée",
            },
            "Documentation": {
                "search": "Rechercher",
                "pageTitle": "Formations et tutoriels",
                "pageHelpTitle":
                    "Découvrez et apprenez la science des données océanographique à votre rythme en fonction de votre besoin.",
                "pageHelpContentP1":
                    "Suivez des formations ou tutoriels interactifs et guidés et",
                "pageHelpContentP2": "contribuer aux ressources de la communauté.",
                "trainings": "Formations",
                "no documentation found": "Aucune documentation ou formation non trouvée",
                "no result found": ({ forWhat }) =>
                    `Aucun résultat trouvé pour ${forWhat}`,
                "check spelling": `Vérifiez l'orthographe ou essayez d'élargir votre recherche.`,
                "go back": "Retourner à toutes les formations",
                "show all": "Afficher tous",
                "discover EDITO": "Découvrir EDITO",
                "Ocean modelling": "La modélisation des océans",
                "Ocean data quality": "Qualité des données océaniques",
                "Data visualization": "Visualisation des données",
                "What-If applications": "What-If scénarios",
                "Focus applications": "Applications dédiées",
                "training courses with R": "Parcours de formation à R",
                "training courses with python": "Parcours de formation à Python",
                "training courses in data science": "Tutoriels de data science",
                "best practices": "Bonnes pratiques, déploiement et automatisation",
                "contributors": "contributeurs",
            },
            "DocumentationCard": {
                "open": "Ouvrir",
                "read": "Lire",
                "run": "Lancer",
                "and": "et",
                "others": "autres",
                "discover": "Découvrir",
                "learn": "Apprendre",
                "consolidate": "Consolider",
                "deepen": "Approfondir",
                "explore": "Explorer",
                "create": "Créer",
                "contribute": "Contribuer",
            },
            "Home": {
                "title": "European Digital Twin Ocean Core Infrastructure",
                "subtitle":
                    "La plateforme européenne pour découvrir, visualiser et exploiter les produits et connaissances afin d'alimenter les jumeaux numériques de l'océan",
                "tutorialCardTitle":
                    "En savoir plus sur la science des données océaniques",
                "tutorialCardText":
                    "Découvrez comment contribuer à la science reproductible grâce à des formations et des tutoriels",
                "tutorialCardButtonLabel": "Consultez nos tutoriels",
                "datalabCardTitle":
                    "Concevoir et exécuter des applications du jumeau numérique de l'océan avec vos outils préférés",
                "datalabCardText":
                    "Tirez parti du cacul proche des données, collaborez, et créez des applications pour les utilisateurs finaux et des processus reproductibles",
                "datalabCardButtonLabel": "Découvrez le Datalab",
                "viewerCardTitle": "Explorer les données océanographiques",
                "viewerCardText":
                    "Consulter les données publiques et créer des catalogues de données privées",
                "viewerCardButtonLabel": "Intégrer les données",
                "viewerCardLeftButtonLabel": "Explorer les données",
                "editoApplicationsTitle": "Découvrez les applications EDITO",
                "diveIntoEditoTitle": "Plongez-vous dans EDITO",
            },
            "BetaCapabilitiesTable": {
                "features": "CARACTÉRISTIQUES",
                "explore1": "EXPLORER",
                "explore2": "Utilisez le Jumeau Numérique Européen de l'océan",
                "explore3": "Ouvrir sans compte",
                "create1": "CRÉER",
                "create2": "Construisez vos composants tiers externes",
                "create3": "Ouvert aux testeurs bêta",
                "contribute1": "CONTRIBUER",
                "contribute2":
                    "Ajoutez des données, des processus, des services et des tutoriels",
                "contribute3": "Ouvert aux testeurs bêta privilégiés",
                "feature1":
                    "Explorez le catalogue de données avec le visualiseur et découvrez les ressources publiques",
                "feature2":
                    "Explorez le catalogue de processus (extraction de données, ré-analyse, prévisions, scénarios What-If, etc.)",
                "feature3":
                    "Explorez le catalogue de services (outils, applications, etc.) et accédez aux services publics en cours d'exécution",
                "feature4": "Accédez aux tutoriels et à la documentation",
                "feature5":
                    "Ajoutez et publiez des données & créez votre propre catalogue de données",
                "feature6":
                    "Traitez les données à la demande & lancez des calculs prédéfinis sur les données de navigation",
                "feature7":
                    "Lancez des services océaniques prédéfinis (outils, application What-If, automatisation, IDE de R&D, etc.) & bénéficiez du calcul cloud à proximité des données",
                "feature8":
                    "Connectez des projets et des plateformes externes tels que EuroHPC, GitHub, GitLab, Kaggle, etc.",
                "feature9":
                    "Publiez des indicateurs de contrôle de qualité sur les données et les processus",
                "feature10": "Ajoutez et publiez de nouveaux services dans le catalogue",
                "feature11": "Ajoutez et publiez de nouveaux processus dans le catalogue",
                "feature12":
                    "Ajoutez et publiez de nouveaux tutoriels pour promouvoir votre contribution",
                "feature13":
                    "Travaillez en groupes avec vos coéquipiers, partenaires ou collaborateurs",
                "feature14":
                    "Demandez plus de capacités de ressources (calcul & stockage)",
                "feature15": "Bénéficiez de GPU",
                "feature16":
                    "Bénéficiez de clusters de calcul en cloud distribués (Dask, Spark, etc.)",
                "feature17": "Bénéficiez d'un support utilisateur privilégié",
            },
        },
        /* spell-checker: disable */
        "it": {
            "AppHeader": {
                "platform": "Piattaforma",
                "viewer": "Integrare i dati",
                "exploreViewer": "Esplorare i dati",
                "home": "Accoglienza",
                "datalab": "Datalab",
                "tutorials": "Formazioni e tutorials",
                "about": "Di EDITO",
                "documentation": "Documentazione",
                "support": "Support",
            },
            "FourOhFour": {
                "not found": "Pagina non trovata",
            },
            "Documentation": {
                "search": "Ricercare",
                "pageTitle": "Formazioni e tutorial",
                "pageHelpTitle":
                    "Scopri e impara la scienza dei dati oceanografici al tuo ritmo, in base alle tue esigenze.",
                "pageHelpContentP1":
                    "Segui formazioni o tutorial interattivi e guidati e",
                "pageHelpContentP2": "contribuisci alle risorse della comunità.",
                "trainings": "Formazioni",
                "no documentation found": "Nessuna documentazione o formazione trovata",
                "no result found": ({ forWhat }) =>
                    `Nessun risultato trovato per ${forWhat}`,
                "check spelling": `Controlla l'ortografia o prova ad ampliare la tua ricerca.`,
                "go back": "Tornare a tutte le formazioni",
                "show all": "Mostrare tutti",
                "discover EDITO": "Scoprire EDITO",
                "Ocean modelling": "La modellazione oceanica",
                "Ocean data quality": "Qualità dei dati oceanici",
                "Data visualization": "Visualizzazione dei dati",
                "What-If applications": "What-If scenari",
                "Focus applications": "Applicazioni specifiche",
                "training courses with R": "Percorso di formazione su R",
                "training courses with python": "Percorso di formazione su Python",
                "training courses in data science": "Tutorial di data science",
                "best practices": "Best practices, deployment e automazione",
                "contributors": "Contributori",
            },
            "DocumentationCard": {
                "open": "Aprire",
                "read": "Leggere",
                "run": "Avviare",
                "and": "e",
                "others": "altri",
                "discover": "Scoprire",
                "learn": "Imparare",
                "consolidate": "Consolidare",
                "deepen": "Approfondire",
                "explore": "Esplora",
                "create": "Crea",
                "contribute": "Contribuisci",
            },
            "Home": {
                "title": "European Digital Twin Ocean Core Infrastructure",
                "subtitle":
                    "La piattaforma europea per scoprire, visualizzare e sfruttare prodotti e conoscenze per potenziare i gemelli digitali dell’oceano.",
                "tutorialCardTitle": "Imparare a conoscere la scienza dei dati oceanici",
                "tutorialCardText":
                    "Scoprire come contribuire alla scienza riproducibile con corsi di formazione ed esercitazioni",
                "tutorialCardButtonLabel": "Consulta i nostri tutorial",
                "datalabCardTitle":
                    "Progettare ed eseguire applicazioni oceaniche gemelle digitali con gli strumenti preferiti",
                "datalabCardText":
                    "Sfruttare l'elaborazione di quasi dati, collaborare e creare app per l'utente finale e processi riproducibili",
                "datalabCardButtonLabel": "Scopri il Datalab",
                "viewerCardTitle": "Esplorare i dati oceanografici",
                "viewerCardText":
                    "Visualizzare i dati pubblici e creare cataloghi di dati privati",
                "viewerCardButtonLabel": "Integrare i dati",
                "viewerCardLeftButtonLabel": "Esplorare i dati",
                "editoApplicationsTitle": "Scopri le applicazioni EDITO",
                "diveIntoEditoTitle": "Immergiti in EDITO",
            },
            "BetaCapabilitiesTable": {
                "features": "CARATTERISTICHE",
                "explore1": "ESPLORA",
                "explore2": "Utilizza il gemello digitale europeo dell’oceano",
                "explore3": "Apri senza account",
                "create1": "CREA",
                "create2": "Costruisci i tuoi componenti esterni di terze parti",
                "create3": "Aperto ai beta tester",
                "contribute1": "CONTRIBUISCI",
                "contribute2": "Aggiungi dati, processi, servizi e tutorial",
                "contribute3": "Aperto ai beta tester privilegiati",
                "feature1":
                    "Esplora il catalogo dei dati con il visualizzatore e scopri le risorse pubbliche",
                "feature2":
                    "Esplora il catalogo dei processi (estrazione dei dati, rianalisi, previsioni, scenari What-If, ecc.)",
                "feature3":
                    "Esplora il catalogo dei servizi (strumenti, applicazioni, ecc.) e accedi ai servizi pubblici in esecuzione",
                "feature4": "Accedi ai tutorial e alla documentazione",
                "feature5": "Aggiungi e pubblica dati & crea il tuo catalogo di dati",
                "feature6":
                    "Elabora i dati su richiesta & avvia calcoli predefiniti sui dati di navigazione",
                "feature7":
                    "Avvia servizi oceanici predefiniti (strumenti, applicazione What-If, automazione, IDE R&D, ecc.) & beneficia del cloud computing vicino ai dati",
                "feature8":
                    "Connetti progetti e piattaforme esterne come EuroHPC, GitHub, GitLab, Kaggle, ecc.",
                "feature9": "Pubblica flag di controllo qualità su dati e processi",
                "feature10": "Aggiungi e pubblica nuovi servizi nel catalogo",
                "feature11": "Aggiungi e pubblica nuovi processi nel catalogo",
                "feature12":
                    "Aggiungi e pubblica nuovi tutorial per promuovere il tuo contributo",
                "feature13":
                    "Lavora in gruppi con i tuoi compagni di squadra, partner o collaboratori",
                "feature14": "Richiedi più capacità di risorse (calcolo & archiviazione)",
                "feature15": "Beneficia delle GPU",
                "feature16":
                    "Beneficia di cluster di cloud computing distribuiti (Dask, Spark, ecc.)",
                "feature17": "Beneficia di un supporto utenti privilegiato",
            },
        },
        "es": {
            "AppHeader": {
                "platform": "Plataforma",
                "viewer": "Explorar datos",
                "exploreViewer": "Integrar datos",
                "home": "Inicio",
                "datalab": "Datalab",
                "tutorials": "Formación y tutoriales",
                "about": "Acerca de EDITO",
                "documentation": "Documentación",
                "support": "Soporte",
            },
            "FourOhFour": {
                "not found": "Página no encontrada",
            },
            "Documentation": {
                "search": "Buscar",
                "pageTitle": "Formación y tutoriales",
                "pageHelpTitle":
                    "Descubre y aprende ciencia de datos oceánicos a tu propio ritmo, según tus necesidades",
                "pageHelpContentP1": "Sigue formación o tutoriales interactivos y",
                "pageHelpContentP2": "contribuye a los recursos de la comunidad.",
                "trainings": "Formaciones",
                "no documentation found": "No se encontró documentación o formación",
                "no result found": ({ forWhat }) =>
                    `No se encontraron resultados para ${forWhat}`,
                "check spelling": "Revisa la ortografía o amplía la búsqueda",
                "go back": "Regresar",
                "show all": "Mostrar todo",
                "discover EDITO": "Descubre EDITO",
                "Ocean modelling": "Modelado oceánico",
                "Ocean data quality": "Calidad de los datos oceánicos",
                "Data visualization": "Visualización de datos",
                "What-If applications": "Aplicaciones hipotéticas",
                "Focus applications": "Aplicaciones focalizadas",
                "training courses with R": "Cursos de formación en R",
                "training courses with python": "Cursos de formación en Python",
                "training courses in data science":
                    "Cursos de formación en ciencia de datos",
                "best practices": "Mejores prácticas",
                "contributors": "colaboradores",
            },
            "DocumentationCard": {
                "open": "Abrir",
                "read": "Leer",
                "run": "Ejecutar",
                "and": "y",
                "others": "otros",
                "discover": "Descubrir",
                "learn": "Aprender",
                "consolidate": "Consolidar",
                "deepen": "Profundizar",
                "explore": "Explorar",
                "create": "Crear",
                "contribute": "Contribuir",
            },
            "Home": {
                "title": "European Digital Twin Ocean Core Infrastructure",
                "subtitle":
                    "La plataforma europea para descubrir, visualizar y aprovechar productos y conocimientos para impulsar gemelos digitales del océano",
                "tutorialCardTitle": "Aprende sobre la ciencia de datos oceánicos",
                "tutorialCardText":
                    "Descubre cómo contribuir a la ciencia reproducible con formaciones y tutoriales",
                "tutorialCardButtonLabel": "Explora nuestros tutoriales",
                "datalabCardTitle":
                    "Diseña y ejecuta aplicaciones gemelas digitales del océano con tus herramientas favoritas",
                "datalabCardText":
                    "Aprovecha la computación cerca de los datos, colabora y construye aplicaciones para el usuario final y procesos reproducibles",
                "datalabCardButtonLabel": "Descubre el Datalab",
                "viewerCardTitle": "Explora datos oceanográficos",
                "viewerCardText":
                    "Visualiza datos públicos y crea catálogos de datos privados",
                "viewerCardButtonLabel": "Explorar datos",
                "viewerCardLeftButtonLabel": "Integrar datos",
                "editoApplicationsTitle": "Descubre las aplicaciones de EDITO",
                "diveIntoEditoTitle": "Sumérgete en EDITO",
            },
            "BetaCapabilitiesTable": {
                "features": "CARACTERÍSTICAS",
                "explore1": "EXPLORAR",
                "explore2": "Utilice el gemelo digital europeo del océano",
                "explore3": "Abrir sin cuenta",
                "create1": "CREAR",
                "create2": "Construye tus componentes externos de terceros",
                "create3": "Abierto a beta-testers",
                "contribute1": "CONTRIBUIR",
                "contribute2": "Añadir datos, procesos, servicios y tutoriales",
                "contribute3": "Abierto a beta-testers privilegiados",
                "feature1":
                    "Explora el catálogo de datos con el visualizador y descubre los activos públicos",
                "feature2":
                    "Explora el catálogo de procesos (extracción de datos, re-análisis, pronóstico, escenarios hipotéticos, etc.)",
                "feature3":
                    "Explora el catálogo de servicios (herramientas, aplicaciones, etc.) y accede a los servicios públicos en funcionamiento",
                "feature4": "Accede a tutoriales y documentación",
                "feature5": "Añadir y publicar datos y crear tu propio catálogo de datos",
                "feature6":
                    "Procesa datos bajo demanda y lanza cálculos predefinidos en los datos de navegación",
                "feature7":
                    "Lanza servicios oceánicos predefinidos (herramientas, aplicación hipotética, automatización, IDE de I+D, etc.) y benefíciate de la computación en la nube cerca de los datos",
                "feature8":
                    "Conecta proyectos y plataformas externas como EuroHPC, GitHub, GitLab, Kaggle, etc.",
                "feature9":
                    "Publicar banderas de control de calidad sobre datos y procesos",
                "feature10": "Añadir y publicar nuevos servicios en el catálogo",
                "feature11": "Añadir y publicar nuevos procesos en el catálogo",
                "feature12":
                    "Añadir y publicar nuevos tutoriales para promover tu contribución",
                "feature13":
                    "Trabaja en grupos con tus compañeros, socios o colaboradores",
                "feature14":
                    "Solicitar más capacidades de recursos (Computación y Almacenamiento)",
                "feature15": "Beneficiarse de GPUs",
                "feature16":
                    "Beneficiarse de clústeres de computación en la nube distribuidos (Dask, Spark, etc.)",
                "feature17": "Beneficiarse de un soporte privilegiado para el usuario",
            },
        },
        "de": {
            "AppHeader": {
                "platform": "Plattform",
                "viewer": "Daten integrieren",
                "exploreViewer": "Daten erforschen",
                "home": "Startseite",
                "datalab": "Datenlabor",
                "tutorials": "Schulungen und Tutorien",
                "about": "Über EDITO",
                "documentation": "Dokumentation",
                "support": "Unterstützung",
            },
            "FourOhFour": {
                "not found": "Seite nicht gefunden",
            },
            "Documentation": {
                "search": "Suche",
                "pageTitle": "Schulungen und Tutorien",
                "pageHelpTitle":
                    "Entdecken und lernen Sie Ozeandatenwissenschaft in Ihrem eigenen Tempo, entsprechend Ihren Bedürfnissen",
                "pageHelpContentP1":
                    "Folgen Sie Schulungen oder interaktiven Tutorials und",
                "pageHelpContentP2": "tragen Sie zu den Gemeinschaftsressourcen bei.",
                "trainings": "Schulungen",
                "no documentation found": "Keine Dokumentation oder Schulung gefunden",
                "no result found": ({ forWhat }) =>
                    `Keine Ergebnisse gefunden für ${forWhat}`,
                "check spelling": "Rechtschreibung überprüfen oder die Suche erweitern",
                "go back": "Zurück",
                "show all": "Alle anzeigen",
                "discover EDITO": "Entdecken Sie EDITO",
                "Ocean modelling": "Ozeanmodellierung",
                "Ocean data quality": "Ozeandatenqualität",
                "Data visualization": "Datenvisualisierung",
                "What-If applications": "Was-wäre-wenn-Anwendungen",
                "Focus applications": "Fokus-Anwendungen",
                "training courses with R": "Schulungskurse mit R",
                "training courses with python": "Schulungskurse mit Python",
                "training courses in data science": "Schulungskurse in Datenwissenschaft",
                "best practices": "Beste Praktiken",
                "contributors": "Mitwirkende",
            },
            "DocumentationCard": {
                "open": "Öffnen",
                "read": "Lesen",
                "run": "Ausführen",
                "and": "und",
                "others": "andere",
                "discover": "Entdecken",
                "learn": "Lernen",
                "consolidate": "Konsolidieren",
                "deepen": "Vertiefen",
                "explore": "Erforschen",
                "create": "Erstellen",
                "contribute": "Beitragen",
            },
            "Home": {
                "title": "European Digital Twin Ocean Core Infrastructure",
                "subtitle":
                    "Die europäische Plattform zur Entdeckung, Visualisierung und Nutzung von Produkten und Wissen zur Unterstützung digitaler Zwillinge des Ozeans",
                "tutorialCardTitle": "Erfahren Sie mehr über die Ozeandatenwissenschaft",
                "tutorialCardText":
                    "Entdecken Sie, wie Sie mit Schulungen und Tutorials zur reproduzierbaren Wissenschaft beitragen können",
                "tutorialCardButtonLabel": "Durchsuchen Sie unsere Tutorials",
                "datalabCardTitle":
                    "Entwerfen und führen Sie digitale Zwillingsanwendungen für den Ozean mit Ihren bevorzugten Werkzeugen aus",
                "datalabCardText":
                    "Nutzen Sie die Datennahe Berechnung, arbeiten Sie zusammen und bauen Sie Endbenutzeranwendungen und reproduzierbare Prozesse",
                "datalabCardButtonLabel": "Entdecken Sie das Datenlabor",
                "viewerCardTitle": "Erforschen Sie ozeanografische Daten",
                "viewerCardText":
                    "Öffentliche Daten anzeigen und private Datenkataloge erstellen",
                "viewerCardButtonLabel": "Daten integrieren",
                "viewerCardLeftButtonLabel": "Daten erforschen",
                "editoApplicationsTitle": "Entdecke die EDITO-Anwendungen",
                "diveIntoEditoTitle": "Tauche ein in EDITO",
            },
            "BetaCapabilitiesTable": {
                "features": "MERKMALE",
                "explore1": "ERFORSCHEN",
                "explore2": "Nutzen Sie den Europäischen Digitalen Zwilling des Ozeans",
                "explore3": "Ohne Konto öffnen",
                "create1": "ERSTELLEN",
                "create2": "Erstellen Sie Ihre externen Drittanbieterkomponenten",
                "create3": "Offen für Beta-Tester",
                "contribute1": "BEITRAGEN",
                "contribute2": "Daten, Prozesse, Dienste und Tutorials hinzufügen",
                "contribute3": "Offen für privilegierte Beta-Tester",
                "feature1":
                    "Erforschen Sie den Datenkatalog mit dem Betrachter und entdecken Sie die öffentlichen Ressourcen",
                "feature2":
                    "Erforschen Sie den Prozesskatalog (Datenextraktion, Re-Analyse, Prognose, Was-wäre-wenn-Szenarien usw.)",
                "feature3":
                    "Erforschen Sie den Dienstkatalog (Werkzeuge, Anwendungen usw.) und greifen Sie auf laufende öffentliche Dienste zu",
                "feature4": "Zugriff auf Tutorials und Dokumentation",
                "feature5":
                    "Daten hinzufügen und veröffentlichen und Ihren eigenen Datenkatalog erstellen",
                "feature6":
                    "Daten auf Abruf verarbeiten und vordefinierte Berechnungen an den Browsing-Daten ausführen",
                "feature7":
                    "Vordefinierte Ozeandienste (Werkzeuge, Was-wäre-wenn-Anwendung, Automatisierung, F&E-IDE usw.) starten und von der Cloud-Datennahe Berechnung profitieren",
                "feature8":
                    "Externe Projekte und Plattformen wie EuroHPC, GitHub, GitLab, Kaggle usw. verbinden",
                "feature9":
                    "Qualitätskontrollflagge über Daten und Prozesse veröffentlichen",
                "feature10": "Neue Dienste zum Katalog hinzufügen und veröffentlichen",
                "feature11": "Neue Prozesse zum Katalog hinzufügen und veröffentlichen",
                "feature12":
                    "Neue Tutorials hinzufügen und veröffentlichen, um Ihren Beitrag zu fördern",
                "feature13":
                    "In Gruppen mit Ihren Teamkollegen, Partnern oder Mitarbeitern arbeiten",
                "feature14":
                    "Mehr Ressourcenkapazitäten anfordern (Rechnen und Speichern)",
                "feature15": "Von GPUs profitieren",
                "feature16":
                    "Von verteilten Cloud-Computing-Clustern profitieren (Dask, Spark usw.)",
                "feature17": "Von einem privilegierten Benutzersupport profitieren",
            },
        },

        /* spell-checker: enable */
    },
);

export { resolveLocalizedString, useLang, useResolveLocalizedString, useTranslation };

export const evtLang = statefulObservableToStatefulEvt({
    "statefulObservable": $lang,
});
