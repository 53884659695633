import editoGlobeLayerPngUrl from "assets/illustrations/Graphic_Bold_v1.png";
import { GlHero } from "gitlanding/GlHero";
import { memo, useEffect } from "react";
import type { Link } from "type-route";
import { createGroup } from "type-route";

import { GlCards } from "gitlanding/GlCards";
import { Button, Text, tss, useStyles as useClasslessStyles } from "theme";
import { routes } from "../../router";

import { ReactComponent as IconTutorialsSvg } from "assets/svg/bookStudyTutorials.svg";
import { ReactComponent as IconDatalabSvg } from "assets/svg/cloudDataDatalab.svg";
import { ReactComponent as IconViewerSvg } from "assets/svg/globeViewer.svg";
import type { HeaderOptions } from "gitlanding/GlTemplate";
import { useTranslation } from "i18n";
import { declareComponentKeys } from "i18nifty";
import { Card as OnyxiaUiCard } from "onyxia-ui/Card";
import { useStateRef } from "powerhooks/useStateRef";
import { id } from "tsafe/id";
import { breakpointsValues, useHeaderHeight } from "../../theme";
import { DocumentationCard } from "../Documentation/DocumentationCard";
import BetaCapabilitiesTable from "./BetaCapabilitiesTable";

Home.routeGroup = createGroup([routes.home]);

Home.headerOptions = id<HeaderOptions>({
    "position": "sticky",
    "isRetracted": "smart",
});

export function Home() {
    const { t } = useTranslation({ Home });
    const { headerHeight } = useHeaderHeight();

    const { classes, cx, theme } = useStyles({
        headerHeight,
    });

    const editoApplicationsData = require("../../assets/edito-applications.json");

    return (
        <>
            <GlHero
                title={t("title")}
                subTitle={t("subtitle")}
                illustration={{
                    "type": "image",
                    "src": editoGlobeLayerPngUrl,
                    "hasShadow": false,
                }}
                classes={{
                    "root": classes.heroRoot,
                    "illustrationWrapper": classes.heroImage,
                    "textAndImageWrapper": classes.heroImageAndTextWrapper,
                    "title": classes.title,
                    "textWrapper": classes.textWrapper,
                    "subtitle": classes.subtitle,
                }}
            />

            <GlCards className={classes.cardSection} title={t("editoApplicationsTitle")}>
                {editoApplicationsData.map((editoApplication: any) => (
                    <DocumentationCard
                        imageUrl={editoApplication.favicon}
                        name={editoApplication.title}
                        abstract={editoApplication.description}
                        authors={[editoApplication.author]}
                        timeRequired={undefined}
                        tags={[]}
                        isDirectory={true}
                        onOpen={() => window.open(editoApplication.url, "_blank")}
                    />
                ))}
            </GlCards>

            <GlCards className={classes.cardSection} title={t("diveIntoEditoTitle")}>
                <Card
                    className={classes.card}
                    Icon={IconTutorialsSvg}
                    title={t("tutorialCardTitle")}
                    text={t("tutorialCardText")}
                    buttonText={t("tutorialCardButtonLabel")}
                    link="https://dive.edito.eu/training"
                />
                <Card
                    className={classes.card}
                    Icon={IconDatalabSvg}
                    title={t("datalabCardTitle")}
                    text={t("datalabCardText")}
                    buttonText={t("datalabCardButtonLabel")}
                    link="https://datalab.dive.edito.eu/"
                />
                <Card
                    className={classes.card}
                    Icon={IconViewerSvg}
                    title={t("viewerCardTitle")}
                    text={t("viewerCardText")}
                    buttonText={t("viewerCardButtonLabel")}
                    leftButtonText={t("viewerCardLeftButtonLabel")}
                    link="https://viewer.dive.edito.eu/"
                    leftLink="https://my-ocean.dive.edito.eu/"
                />
            </GlCards>
            <BetaCapabilitiesTable />
        </>
    );
}

export const { i18n } = declareComponentKeys<
    | "title"
    | "subtitle"
    | "tutorialCardTitle"
    | "tutorialCardText"
    | "tutorialCardButtonLabel"
    | "datalabCardTitle"
    | "datalabCardText"
    | "datalabCardButtonLabel"
    | "viewerCardTitle"
    | "viewerCardText"
    | "viewerCardButtonLabel"
    | "viewerCardLeftButtonLabel"
    | "editoApplicationsTitle"
    | "diveIntoEditoTitle"
>()({ Home });

const useStyles = tss
    .withParams<{
        headerHeight: number | undefined;
    }>()
    .withName({ Home })
    .create(({ theme, headerHeight }) => ({
        "cardSection": {
            "marginTop": 0,
            "paddingTop": 0,
            "marginBottom": theme.spacing(3),
        },
        "card": {
            "marginRight": theme.spacing(3),
            "marginTop": theme.spacing(1),
            "paddingBottom": 0,
            "marginBottom": 0,
        },
        "heroImage": {
            "marginTop": theme.spacing(3),
            "paddingTop": 0,
            "paddingBottom": 0,
            "marginBottom": 0,

            "position": "relative",
            "maxWidth": 400,
            "minWidth": "unset",
            ...(theme.windowInnerWidth >= breakpointsValues.xl
                ? {
                      "top": theme.spacing(5),
                      "transform": `scale(1.5)`,
                      "left": -theme.spacing(9),
                  }
                : {}),
            ...(theme.windowInnerWidth <= breakpointsValues.xl
                ? {
                      //   "marginTop": theme.spacing(0),
                      "top": -theme.spacing(1),

                      "transform": `scale(1.3)`,
                      "left": -theme.spacing(7),
                  }
                : {}),
            ...(theme.windowInnerWidth <= breakpointsValues["lg+"]
                ? {
                      "top": -theme.spacing(5),
                      "transform": "scale(1.2)",
                  }
                : {}),
            ...(theme.windowInnerWidth <= breakpointsValues.lg
                ? {
                      "top": -theme.spacing(6),
                      "transform": "scale(1.1)",
                      "left": -theme.spacing(6),
                  }
                : {}),
            ...(theme.windowInnerWidth <= breakpointsValues.md
                ? {
                      "top": -theme.spacing(3),
                      "transform": "scale(1)",
                      "left": -theme.spacing(3),
                  }
                : {}),
            ...(theme.windowInnerWidth <= breakpointsValues.sm
                ? {
                      "marginTop": theme.spacing(0),
                      "top": -theme.spacing(5),
                      "transform": "scale(0.8)",
                      "left": -theme.spacing(0),
                  }
                : {}),
        },
        "heroRoot": {
            "marginTop": headerHeight ?? undefined,
            "marginBottom": 0,
            "paddingBottom": 0,
        },
        "heroImageAndTextWrapper": {
            "alignItems": "flex-start",
            "minHeight": 0,
            "justifyContent": "space-between",
            "paddingTop": 0,
            "paddingBottom": 0,
            "marginBottom": 0,
        },
        "textWrapper": {
            "marginRight": 0,
            "zIndex": 2,
            "paddingTop": 0,
            "paddingBottom": 0,
            "marginBottom": 0,
        },
        "title": {
            "paddingBottom": 0,
            "width": (() => {
                if (theme.windowInnerWidth >= breakpointsValues.xl) {
                    return 1000;
                }
                if (theme.windowInnerWidth >= breakpointsValues["lg+"]) {
                    return 800;
                }

                if (theme.windowInnerWidth >= breakpointsValues.lg) {
                    return 650;
                }

                if (theme.windowInnerWidth >= breakpointsValues.md) {
                    return 600;
                }
                if (theme.windowInnerWidth >= breakpointsValues.sm) {
                    return 500;
                }

                return "none";
            })(),
        },
        "subtitle": {
            "paddingBottom": 0,

            "width": (() => {
                if (
                    theme.windowInnerWidth < breakpointsValues["lg+"] &&
                    theme.windowInnerWidth >= breakpointsValues.md
                ) {
                    return 300;
                }

                return "none";
            })(),
        },
    }));

const { Card } = (() => {
    type Props = {
        className?: string;
        title: string;
        text: string;
        buttonText: string;
        leftButtonText?: string;
        Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
        link: Link | string;
        leftLink?: Link | string;
    };

    const Card = memo((props: Props) => {
        const {
            title,
            text,
            buttonText,
            leftButtonText,
            Icon,
            className,
            link,
            leftLink,
        } = props;

        const { css, cx, theme } = useClasslessStyles();

        const iconRef = useStateRef<SVGSVGElement>(null);

        useEffect(() => {
            iconRef.current!.querySelectorAll("g").forEach(g => {
                g.setAttribute(
                    "fill",
                    g.classList.contains("colorPrimary")
                        ? theme.colors.useCases.typography.textFocus
                        : theme.colors.useCases.typography.textPrimary,
                );
                g.setAttribute(
                    "stroke",
                    g.classList.contains("colorPrimary")
                        ? theme.colors.useCases.typography.textFocus
                        : theme.colors.useCases.typography.textPrimary,
                );
            });
        }, [theme, iconRef.current]);

        return (
            <OnyxiaUiCard
                className={cx(
                    css({
                        "display": "flex",
                        "flexDirection": "column",
                        "padding": theme.spacing(4),
                        "backgroundColor": theme.isDarkModeEnabled
                            ? "#383E50"
                            : undefined,
                    }),
                    className,
                )}
            >
                <div className={css({ "display": "flex" })}>
                    <Icon ref={iconRef} width={120} height={120} />
                    <div
                        className={css({
                            "flex": 1,
                            "display": "flex",
                            "alignItems": "center",
                            ...theme.spacing.rightLeft("padding", 2),
                        })}
                    >
                        <Text typo="section heading">{title}</Text>
                    </div>
                </div>
                <div
                    className={css({
                        "flex": 1,
                        "display": "flex",
                        "flexDirection": "column",
                        "paddingTop": theme.spacing(2),
                    })}
                >
                    <div className={css({ "flex": 1 })}>
                        <Text typo="body 1">{text}</Text>
                    </div>
                    <div
                        className={css({
                            "marginTop": theme.spacing(1),
                            "display": "flex",
                        })}
                    >
                        {!leftButtonText && <div style={{ "flex": 1 }} />}
                        {leftButtonText && (
                            <>
                                <Button
                                    variant="secondary"
                                    {...(typeof leftLink === "string"
                                        ? { "href": leftLink }
                                        : { ...leftLink, "doOpenNewTabIfHref": false })}
                                >
                                    {leftButtonText}
                                </Button>
                                <div style={{ "flex": 1 }} />
                            </>
                        )}
                        <Button
                            variant="secondary"
                            {...(typeof link === "string"
                                ? { "href": link }
                                : { ...link, "doOpenNewTabIfHref": false })}
                        >
                            {buttonText}
                        </Button>
                    </div>
                </div>
            </OnyxiaUiCard>
        );
    });

    return { Card };
})();
